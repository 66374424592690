import { withStyles, WithStyles } from "@material-ui/core";
import cn from "classnames";
import React from "react";
import ReactYoutube from "react-youtube";

import { styles } from "./YoutubeVideo.styles";

export type YoutubeVideoProps = WithStyles<typeof styles> & {
  className?: string;
  videoId: string;
};

class YoutubeVideo extends React.PureComponent<YoutubeVideoProps> {
  public render() {
    const { classes, className, videoId } = this.props;

    return (
      <ReactYoutube
        videoId={videoId}
        className={classes.youtube}
        containerClassName={cn(className, classes.youtubeContainer)}
      />
    );
  }
}

export default withStyles(styles)(YoutubeVideo);
