import { Button, withStyles, WithStyles } from "@material-ui/core";

import cn from "classnames";
import React from "react";
import { T, withLocale, WithLocale } from "react-targem";

import { styles } from "./LearnHow.styles";
import ModalVideo from "./ModalVideo";

export type LearnHowProps = WithStyles<typeof styles> &
  WithLocale & {
    className?: string;
  };

export type LearnHowState = {
  isOpen: boolean;
};

class LearnHow extends React.PureComponent<LearnHowProps, LearnHowState> {
  public state: LearnHowState = {
    isOpen: false,
  };

  public render() {
    const { classes, className } = this.props;
    const { isOpen } = this.state;

    return (
      <>
        <Button
          variant="outlined"
          onClick={this.handleOpenVideo}
          className={cn(className, classes.button)}>
          <T message="Learn how" />
        </Button>
        <ModalVideo isOpen={isOpen} onClose={this.handleCloseVideo} />
      </>
    );
  }

  private handleToggleVideo = (isOpen: boolean) => this.setState({ isOpen });
  private handleOpenVideo = () => this.handleToggleVideo(true);
  private handleCloseVideo = () => this.handleToggleVideo(false);
}

export default withLocale(withStyles(styles)(LearnHow));
