import { Hidden, Typography, withStyles, WithStyles } from "@material-ui/core";
import cn from "classnames";
import React from "react";

// tslint:disable-next-line: no-relative-imports
import { SwitcherItemElement } from "../SwitcherItem";
import { styles } from "./List.styles";

export type ListProps = WithStyles<typeof styles> & {
  className?: string;
  content: SwitcherItemElement;
};

class List extends React.PureComponent<ListProps> {
  public render() {
    const { classes, className, content } = this.props;
    const { children, title, listTitle, description } = content.props;
    const itemsCount = React.Children.count(children);

    return (
      <React.Fragment>
        <Hidden mdUp>
          <Typography className={classes.title} variant="h6">
            {title}
          </Typography>
        </Hidden>
        <ul className={cn(classes.list, className)}>
          <Typography variant="body1" className={classes.description}>
            {description}
          </Typography>
          {listTitle && (
            <Typography variant="body1" className={classes.listTitle}>
              {listTitle}:
            </Typography>
          )}
          {React.Children.map(children, (child, index) => (
            <Typography
              variant="body1"
              key={index}
              className={classes.listItem}>
              {child}
              {index === itemsCount - 1 ? "." : ";"}
            </Typography>
          ))}
        </ul>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(List);
