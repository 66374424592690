import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
      paddingTop: theme.spacing(7),
    },
    greenDots: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
    },
    paper: {
      padding: theme.spacing(6),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(3),
        paddingBottom: theme.spacing(1),
      },
    },
    title: {
      marginBottom: theme.spacing(2),
    },
    infoContainer: {
      margin: theme.spacing(5, 5.75, 0),
      width: "auto",
      display: "flex",
      flexWrap: "wrap",
      [theme.breakpoints.down("md")]: {
        margin: 0,
      },
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(0, -4.25),
      },
    },
    valueBlock: {
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: "25%",
      margin: theme.spacing(5, 4.25),
      [theme.breakpoints.down("md")]: {
        margin: theme.spacing(0, 4.25, 6),
        flexBasis: "40%",
      },
    },
    ecologicalBlock: {
      flexBasis: "auto",
      flexDirection: "row",
      alignItems: "flex-start",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
    largeIcon: {
      [theme.breakpoints.down("xs")]: {
        marginLeft: theme.spacing(0.5),
      },
    },
  });
