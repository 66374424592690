import { createStyles, Theme } from "@material-ui/core";
import { calcVideoHeight } from "@src/utils/styles";

export const styles = (theme: Theme) =>
  createStyles({
    screenContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginRight: theme.spacing(-2.5),
      right: 0,
      zIndex: 1,
      [theme.breakpoints.down("md")]: {
        marginRight: 0,
        marginTop: theme.spacing(6),
      },
    },
    screen: {
      borderRadius: "20px",
      padding: "4px 3px",
      backgroundColor: theme.palette.common.white,
      overflow: "hidden",
    },
    screenBorder: {
      border: `1px solid ${theme.palette.grey[50]}`,
      padding: "26px 15px",
      position: "relative",
      "&:before": {
        content: "''",
        position: "absolute",
        width: "10px",
        height: "10px",
        boxShadow: theme.shadows[0],
        backgroundColor: theme.palette.grey[100],
        top: "8px",
        right: 0,
        left: 0,
        margin: "auto",
        borderRadius: "50%",
      },
    },
    videoContainer: {
      backgroundColor: theme.palette.common.black,
      width: "570px",
      height: calcVideoHeight("570px"),
      maxWidth: "65vw",
      maxHeight: calcVideoHeight("65vw"),
      outline: `1px solid rgba(0, 0, 0, 0.56)`,
    },
    screenLeg: {
      position: "relative",
      height: "25px",
      backgroundColor: theme.palette.grey[50],
      borderBottomLeftRadius: "45%",
      borderBottomRightRadius: "45%",
      width: "120%",
      marginTop: theme.spacing(-1.5),
      "&:before": {
        content: "''",
        width: "120px",
        height: "12px",
        backgroundImage: `linear-gradient(90deg,${theme.palette.grey[200]}, ${theme.palette.grey[100]}, ${theme.palette.grey[50]}, ${theme.palette.grey[100]}, ${theme.palette.grey[200]})`,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        margin: "auto",
        borderBottomRightRadius: "16px",
        borderBottomLeftRadius: "16px",
      },
      "&:after": {
        content: "''",
        position: "absolute",
        height: "1px",
        width: "100%",
        top: "13px",
        left: 0,
        backgroundImage: `linear-gradient(0.15turn, ${theme.palette.grey[200]}, ${theme.palette.grey[50]}, ${theme.palette.grey[200]})`,
      },
    },
  });
