import { Typography, withStyles, WithStyles } from "@material-ui/core";
import cn from "classnames";
import React from "react";

import { styles } from "./ValueBlock.styles";

export type ValueBlockProps = WithStyles<typeof styles> & {
  className?: string;
  title: React.ReactNode;
  text?: React.ReactNode;
  icon: SvgComponent;
  iconClassName?: string;
};

class ValueBlock extends React.PureComponent<ValueBlockProps> {
  public render() {
    const {
      classes,
      className,
      children,
      icon: Icon,
      iconClassName,
      title,
      text,
    } = this.props;

    return (
      <div className={cn(classes.container, className)}>
        <div className={classes.iconContainer}>
          <Icon className={cn(classes.icon, iconClassName)} />
        </div>
        <div>
          <Typography className={classes.title} variant="h6">
            {title}
          </Typography>
          <Typography className={classes.text} variant="body1">
            {text || children}
          </Typography>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ValueBlock);
