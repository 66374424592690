import {
  Container,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import cn from "classnames";
import React from "react";
import { T } from "react-targem";

import { EnterButton } from "@src/components/CommonButtons";

import LearnHow from "./LearnHow";
import { styles } from "./PerfectMatch.styles";

export type PerfectMatchProps = WithStyles<typeof styles> & {
  className?: string;
};

class PerfectMatch extends React.PureComponent<PerfectMatchProps> {
  public render() {
    const { className, classes } = this.props;
    return (
      <div className={cn(className, classes.blockContainer)}>
        <Container className={classes.contentContainer}>
          <Typography className={classes.title} variant="h1" gutterBottom>
            <T message="The perfect match" />
          </Typography>
          <Typography className={classes.subtitle} variant="h5">
            <T message="Cross ride optimization platform" />
          </Typography>
          <div className={classes.buttonsContainer}>
            <LearnHow className={classes.button} />
            <EnterButton className={classes.button} />
          </div>
        </Container>
      </div>
    );
  }
}

export default withStyles(styles)(PerfectMatch);
