import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    videoContainer: {
      overflow: "hidden",
    },
    videoWrapper: {
      zIndex: -1,
      maxHeight: "800px",
      [theme.breakpoints.down("xs")]: {
        maxHeight: "687px",
      },
    },
    video: {
      [theme.breakpoints.down("lg")]: {
        width: "auto",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "-50%",
      },
      [theme.breakpoints.down("xs")]: {
        marginLeft: "-80%",
      },
      "@media (max-width: 440px)": {
        marginLeft: "-150%",
      },
    },
  });
