import { SvgIconComponent } from "@material-ui/icons";
import React from "react";

import { GatsbyQueriedImage } from "@src/types";

export type SwitcherItemProps = {
  buttonIcon: SvgComponent | SvgIconComponent;
  children: React.ReactNode;
  description: React.ReactNode;
  image: GatsbyQueriedImage;
  listTitle?: React.ReactNode;
  title: React.ReactNode;
  video: string;
  actionButtons: React.ReactNode;
};

export type SwitcherItemElement = React.ReactElement<SwitcherItemProps>;

class SwitcherItem extends React.PureComponent<SwitcherItemProps> {
  public render() {
    return null;
  }
}

export default SwitcherItem;
