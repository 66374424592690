import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(-3),
    },
    buttonsContainer: {
      margin: theme.spacing(0, -3),
    },
    iconLink: {
      margin: theme.spacing(3),
      display: "inline-block",
    },
    icon: {
      width: "36px",
      height: "36px",
      "&:hover path:first-child": {
        fill: theme.palette.primary.main,
      },
    },
  });
