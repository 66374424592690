import { createStyles, Theme } from "@material-ui/core";

const videoWidth = "calc(100vw - 160px - 96px)";
const tabletVideoWidth = "calc(100vw - 16px - 48px)";

export const styles = (theme: Theme) =>
  createStyles({
    paper: {
      width: "1120px",
      maxWidth: "calc(100vw - 96px)",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "calc(100vw - 48px)",
        margin: theme.spacing(3),
      },
    },
    iconButton: {
      position: "absolute",
      padding: theme.spacing(0.5),
      top: theme.spacing(0.5),
      right: theme.spacing(0.5),
    },
    icon: {
      color: theme.palette.primary.main,
    },
    dialogContent: {
      padding: theme.spacing(4, 10),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(5, 1),
      },
    },
    youtubeContainer: {
      height: "535.5px",
      [theme.breakpoints.down("md")]: {
        width: videoWidth,
        height: `calc(${videoWidth} / 16 * 9)`,
      },
      [theme.breakpoints.down("sm")]: {
        width: tabletVideoWidth,
        height: `calc(${tabletVideoWidth} / 16 * 9)`,
      },
    },
    youtube: {
      width: "100%",
      height: "100%",
    },
  });
