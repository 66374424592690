import {
  Container,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { PlayCircleOutline } from "@material-ui/icons";
import cn from "classnames";
import React from "react";
import { T, withLocale, WithLocale } from "react-targem";

import ButtonLink from "@src/components/ButtonLink";
import GreenDots from "@src/components/GreenDots";
import YoutubeVideo from "@src/components/YoutubeVideo";

import Screen from "./Screen";
import { styles } from "./WhatIsTrucknet.styles";

export type WhatIsTrucknetProps = WithLocale &
  WithStyles<typeof styles> & {
    className?: string;
  };

class WhatIsTrucknet extends React.PureComponent<WhatIsTrucknetProps> {
  public render() {
    const { classes, className } = this.props;

    return (
      <div className={classes.blockContainer}>
        <Container className={cn(classes.container, className)}>
          <div className={classes.textContainer}>
            <Typography className={classes.title} variant="h3">
              <T message="Trucknet introduction" />
            </Typography>
            <Typography variant="subtitle1" className={classes.textAbout}>
              <T message="Trucknet is a cloud-based transport optimization platform, founded in 2016, rolling out across Europe and Israel, including France, Spain, Portugal, and Romania." />
            </Typography>
            <ButtonLink
              color="secondary"
              variant="contained"
              className={classes.button}
              to={`https://www.youtube.com/watch?v=${this.getVideoId()}`}
              external>
              <PlayCircleOutline className={classes.icon} />
              <T message="Watch" />
            </ButtonLink>
          </div>
          <Screen>
            <YoutubeVideo
              videoId={this.getVideoId()}
              className={classes.youtubeContainer}
            />
          </Screen>
          <GreenDots className={classes.greenDots} />
        </Container>
      </div>
    );
  }

  private getVideoId() {
    const localeToVideoId: { [locale: string]: string } = {
      en: "5ZB-tIMSSnI",
      ro: "MJIyZrno-bQ",
      fr: "4BksiCSM0uU",
      he: "uJpeAXzOGHY",
    };
    return localeToVideoId[this.props.locale] || localeToVideoId.en;
  }
}

export default withLocale(withStyles(styles)(WhatIsTrucknet));
