import { withStyles, WithStyles } from "@material-ui/core";
import cn from "classnames";
import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { CSSTransitionClassNames } from "react-transition-group/CSSTransition";

import { styles } from "./Transition.styles";

export type TransitionProps = {
  changeKey: string | number;
  className?: string;
  duration?: number;
  transitionClassNames?: CSSTransitionClassNames;
};

class Transition extends React.PureComponent<
  WithStyles<typeof styles> & TransitionProps
> {
  public render() {
    const {
      changeKey,
      children,
      classes,
      className,
      duration,
      transitionClassNames,
    } = this.props;
    return (
      <TransitionGroup className={cn(classes.transitionGroup, className)}>
        <CSSTransition
          key={changeKey}
          timeout={duration || 250}
          classNames={{
            enter: classes.enter,
            enterActive: classes.enterActive,
            exit: classes.exit,
            exitActive: classes.exitActive,
            ...transitionClassNames,
          }}>
          {children}
        </CSSTransition>
      </TransitionGroup>
    );
  }
}

const TransitionStyled = withStyles(styles)(Transition);
TransitionStyled.defaultProps = {
  duration: 250,
};
export default TransitionStyled;
