import {
  Container,
  Hidden,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import cn from "classnames";
import GatsbyImage from "gatsby-image";
import React from "react";
import { T } from "react-targem";

import { EnterButton } from "@src/components/CommonButtons";
import { withPageData, WithPageData } from "@src/contexts/PageDataContext";
import { IndexPageProps } from "@src/templates/IndexPage";

import { styles } from "./TryTrucknet.styles";

export type TryTrucknetProps = WithPageData<IndexPageProps> &
  WithStyles<typeof styles> & {
    className?: string;
  };

class TryTrucknet extends React.PureComponent<TryTrucknetProps> {
  public render() {
    const { className, classes, pageData } = this.props;
    return (
      <Container className={cn(classes.container, className)}>
        <div className={classes.text}>
          <Typography className={classes.title} variant="h4">
            <T message="Try Trucknet" />!
          </Typography>
          <Typography className={classes.subtitle} variant="body1">
            <T message="Find the perfect match" />.
          </Typography>

          <Hidden smDown implementation="css">
            <EnterButton className={classes.button} />
          </Hidden>
        </div>

        <Hidden className={classes.image} smDown implementation="css">
          <GatsbyImage
            fluid={pageData.tryTrucknetDesktop.childImageSharp.fluid}
          />
        </Hidden>
        <Hidden mdUp>
          <GatsbyImage
            className={classes.image}
            fluid={pageData.tryTrucknetMobile.childImageSharp.fluid}
          />
        </Hidden>

        <Hidden mdUp>
          <EnterButton className={classes.button} />
        </Hidden>
      </Container>
    );
  }
}

export default withPageData(withStyles(styles)(TryTrucknet));
