import {
  Dialog,
  DialogContent,
  IconButton,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";
import { withLocale, WithLocale } from "react-targem";
import ReactYoutube from "react-youtube";

import { styles } from "./ModalVideo.styles";

export type PerfectMatchProps = WithStyles<typeof styles> &
  WithLocale & {
    isOpen: boolean;
    onClose(): void;
  };

class ModalVideo extends React.PureComponent<PerfectMatchProps> {
  public render() {
    const { isOpen, onClose, classes } = this.props;

    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        classes={{ paper: classes.paper }}>
        <IconButton onClick={onClose} className={classes.iconButton}>
          <Close className={classes.icon} />
        </IconButton>
        <DialogContent className={classes.dialogContent}>
          <ReactYoutube
            videoId={this.getVideoId()}
            className={classes.youtube}
            containerClassName={classes.youtubeContainer}
            opts={{}}
          />
        </DialogContent>
      </Dialog>
    );
  }

  private getVideoId = () => {
    const localeToVideoId: { [locale: string]: string } = {
      fr: "srMSQZ4Tk88",
      he: "uJpeAXzOGHY",
    };
    return localeToVideoId[this.props.locale] || localeToVideoId.fr;
  };
}

export default withLocale(withStyles(styles)(ModalVideo));
