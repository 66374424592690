import { createStyles, Theme } from "@material-ui/core";
import { calcVideoHeight } from "@src/utils/styles";

const mdVideoWidth = "calc(50vw - 20px - 40px)";
const tabletVideoWidth = "calc(100vw - 64px)";
const mobileVideoWidth = "calc(100vw - 48px)";

export const styles = (theme: Theme) =>
  createStyles({
    youtubeContainer: {
      width: "540px",
      height: calcVideoHeight("540px"),
      margin: theme.spacing(10, 0),
      [theme.breakpoints.down("md")]: {
        width: mdVideoWidth,
        height: calcVideoHeight(mdVideoWidth),
      },
      [theme.breakpoints.down("sm")]: {
        width: tabletVideoWidth,
        height: calcVideoHeight(tabletVideoWidth),
      },
      [theme.breakpoints.down("xs")]: {
        margin: theme.spacing(5, 0),
        width: mobileVideoWidth,
        height: calcVideoHeight(mobileVideoWidth),
      },
    },
    youtube: {
      boxShadow: theme.shadows[12],
      width: "100%",
      height: "100%",
    },
  });
