import { createStyles, Theme } from "@material-ui/core";

const semiTransparentBackground = "rgba(255, 255, 255, 0.9)";

export const styles = (theme: Theme) =>
  createStyles({
    button: {
      height: "60px",
      padding: theme.spacing(1, 6),
      backgroundColor: semiTransparentBackground,
      color: theme.palette.text.secondary,
      "&:hover": {
        backgroundColor: semiTransparentBackground,
      },
      "&$active": {
        color: theme.palette.primary.main,
        borderBottom: `4px solid ${theme.palette.secondary.main}`,
        backgroundColor: `${theme.palette.common.white}`,
        boxShadow: theme.shadows[2],
      },
    },
    active: {},
    text: {},
    icon: {
      fill: theme.palette.text.secondary,
      marginRight: theme.spacing(2.5),
      "$active &": {
        fill: theme.palette.primary.main,
      },
    },
  });
