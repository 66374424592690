import React from "react";

import SeoHead from "@src/components/SeoHead";
import IndexLayout from "@src/layouts/IndexLayout";
import { GatsbyQueriedImagesList, PageWithImagesProps } from "@src/types";
import { t } from "@src/utils/translations";

import HeaderWithVideo from "./HeaderWithVideo";
import IndexTemplate from "./IndexTemplate";

export type IndexPageProps = PageWithImagesProps<
  | "managerInterface"
  | "driverInterface"
  | "avatar1"
  | "avatar2"
  | "avatar3"
  | "backgroundPartnership"
  | "tryTrucknetDesktop"
  | "tryTrucknetMobile",
  { partners: GatsbyQueriedImagesList }
> & {};

class IndexPage extends React.Component<IndexPageProps> {
  public render() {
    return (
      <IndexLayout
        headerStyle="light"
        {...this.props}
        headerComponent={HeaderWithVideo}>
        <SeoHead
          title={t("The perfect match")}
          description={t(
            "Trucknet is a cloud-based transport optimization platform. It improves the efficiency and profitability of transportation companies while reducing the impact on the environment",
          )}
          keywords={[
            t("sustainable"),
            t("extra profit"),
            t("security"),
            t("efficiency"),
          ]}
        />
        <IndexTemplate />
      </IndexLayout>
    );
  }
}

export default IndexPage;
