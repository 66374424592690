import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      marginBottom: theme.spacing(25),
      [theme.breakpoints.up("lg")]: {
        paddingLeft: theme.spacing(12),
        paddingRight: theme.spacing(12),
      },
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
        display: "block",
      },
    },
    text: {
      flex: 1,
    },
    title: {
      marginTop: "13%",
    },
    subtitle: {
      marginTop: theme.spacing(1),
      fontSize: "1rem",
    },
    button: {
      marginTop: theme.spacing(6),
    },
    image: {
      flex: 2.2,
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(6),
        marginRight: theme.spacing(-3),
        marginLeft: theme.spacing(-3),
        maxHeight: "300px",
        "& img": {
          objectFit: "contain !important" as "contain",
        },
      },
    },
  });
