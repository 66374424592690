import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    blockContainer: {
      height: "488px",
      [theme.breakpoints.down("md")]: {
        height: "520px",
      },
      [theme.breakpoints.down("xs")]: {
        height: "412px",
      },
    },
    contentContainer: {
      top: theme.spacing(25.5),
      textAlign: "center",
    },
    title: {
      marginBottom: theme.spacing(2),
      color: theme.palette.common.white,
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(1),
      },
    },
    subtitle: {
      color: theme.palette.common.white,
      marginBottom: theme.spacing(6),
    },
    image: {
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    },
    buttonsContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      margin: theme.spacing(0, -0.5),
    },
    button: {
      margin: theme.spacing(0, 0.5, 1),
    },
  });
