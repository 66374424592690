import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    button: {
      color: theme.palette.common.white,
      borderColor: theme.palette.common.white,
      minWidth: "180px",
    },
  });
