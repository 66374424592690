import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    container: {},
    title: {
      marginBottom: theme.spacing(8),
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(5),
        textAlign: "center",
      },
    },
    interfaceSwitcher: {},
  });
