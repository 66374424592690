import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
    },
    iconContainer: {
      height: theme.spacing(9),
      width: theme.spacing(9),
      margin: theme.spacing(0, 5, 3, 0),
      [theme.breakpoints.down("xs")]: {
        marginBottom: theme.spacing(2),
        height: theme.spacing(5),
        width: theme.spacing(5),
      },
    },
    icon: {
      height: "100%",
      width: "auto",
    },
    title: {
      marginBottom: theme.spacing(1),
    },
    text: {
      [theme.breakpoints.up("sm")]: {
        textAlign: "justify",
      },
    },
  });
