import { withStyles, WithStyles } from "@material-ui/core";
import React from "react";

import headerMp4 from "@src/assets/video/header.mp4";
import HeaderWithBackground from "@src/components/HeaderWithBackground";
import Video from "@src/components/Video";
import { HeaderComponentProps } from "@src/layouts/MainLayout";

import { styles } from "./HeaderWithVideo.styles";

export type HeaderWithVideoProps = WithStyles<typeof styles> &
  HeaderComponentProps;

class HeaderWithVideo extends React.Component<HeaderWithVideoProps> {
  public render() {
    const { classes, header } = this.props;
    return (
      <HeaderWithBackground header={header} className={classes.videoContainer}>
        <Video
          containerClassName={classes.videoWrapper}
          className={classes.video}
          src={headerMp4}
        />
      </HeaderWithBackground>
    );
  }
}

export default withStyles(styles)(HeaderWithVideo);
