import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    blockContainer: {
      width: "100vw",
      position: "relative",
      padding: theme.spacing(10, 0),
    },
    backgroundContainer: {
      position: "absolute",
      zIndex: -1,
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    background: {
      width: "100%",
      height: "100%",
    },
    container: {
      textAlign: "center",
    },
    paper: {
      padding: theme.spacing(5, 14),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(5, 2),
      },
    },
    title: {
      marginBottom: theme.spacing(2.5),
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(5),
      },
    },
    grid: {
      [theme.breakpoints.up("lg")]: {
        justifyContent: "center",
      },
      [theme.breakpoints.between("sm", "md")]: {
        width: "100%",
        margin: 0,
      },
    },
    partner: {
      padding: theme.spacing(0.5, 2.5),
      "@media (max-width: 440px)": {
        padding: theme.spacing(0.5),
      },
    },
    item: {},
    logo: {
      height: "auto",
      width: "auto",
    },
    signUp: {
      marginTop: theme.spacing(10),
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(5),
      },
    },
  });
