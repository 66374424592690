import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    description: {
      maxWidth: "486px",
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(5),
      },
    },
    list: {
      margin: 0,
      padding: 0,
      listStyleType: "none",
    },
    listTitle: {
      marginBottom: theme.spacing(1),
    },
    listItem: {
      display: "block",
      marginLeft: theme.spacing(2.5),
      position: "relative",
      "&:before": {
        content: `"•"`,
        color: theme.palette.text.primary,
        position: "absolute",
        left: theme.spacing(-2.5),
      },
    },
  });
