import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    perfectMatch: {
      marginTop: theme.spacing(13),
      marginBottom: theme.spacing(7),
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(12.5),
      },
    },
    coreValues: {
      marginTop: theme.spacing(-7),
    },
    whatIsTrucknet: {
      marginTop: theme.spacing(20),
    },
    easyToUse: {
      marginTop: theme.spacing(20),
    },
    reviews: {
      marginTop: theme.spacing(20),
    },
    partnership: {
      marginTop: theme.spacing(20),
    },
    tryTrucknet: {
      marginTop: theme.spacing(20),
    },
  });
