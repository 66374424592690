import {
  Container,
  Paper,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import cn from "classnames";
import React from "react";
import { T } from "react-targem";

import EfficiencySvg from "@src/assets/icons/Efficiency.svg";
import ProfitSvg from "@src/assets/icons/Profit.svg";
import SecuritySvg from "@src/assets/icons/Security.svg";
import SustainableSvg from "@src/assets/icons/Sustainable.svg";
import GreenDots from "@src/components/GreenDots";

import { styles } from "./CoreValues.styles";
import ValueBlock from "./ValueBlock";

export type CoreValuesProps = WithStyles<typeof styles> & {
  className?: string;
};

class CoreValues extends React.PureComponent<CoreValuesProps> {
  public render() {
    const { classes, className } = this.props;
    return (
      <Container className={cn(className, classes.container)}>
        <GreenDots className={classes.greenDots} />
        <Paper className={classes.paper} elevation={6}>
          <Typography variant="h3" align="center" className={classes.title}>
            <T message="Trucknet" />
          </Typography>
          <Typography variant="h5" align="center">
            <T message="Cross company optimization for heavy duty fleets" />
          </Typography>
          <div className={classes.infoContainer}>
            <ValueBlock
              icon={SustainableSvg}
              title={<T message="Sustainable" />}
              className={cn(classes.valueBlock, classes.ecologicalBlock)}>
              <T message="Connecting to Trucknet's automatic platform offers logistics and transportation companies opportunities for saving resources and cutting costs. In addition, the system delivers an online tool for heavy fleet companies to calculate their CO2 emissions. The heavy vehicle industry is expected to demonstrate social and environmental responsibility, and to comply with new EU standards for reducing CO2 emissions by 2030. Here is where Trucknet adds its unique solution. Quantifying greenhouse gas (GHG) emissions could provide possibilities for companies interested in carbon emission trading." />
            </ValueBlock>
            <ValueBlock
              icon={ProfitSvg}
              title={<T message="Extra profit" />}
              className={classes.valueBlock}>
              <T message="Trucknet can assist a company in earning extra profit by selling its empty truck rides to other transportation companies. The process provides better visibility during the outsourcing delivery." />
            </ValueBlock>
            <ValueBlock
              icon={SecuritySvg}
              title={<T message="Security" />}
              className={classes.valueBlock}>
              <T message="Trucknet is a GDPR (General Data Protection Regulation) compliant system, which stores all companies' data in an encrypted database. Security is the primary goal for the development team and the company is ISO 9001 certified." />
            </ValueBlock>
            <ValueBlock
              icon={EfficiencySvg}
              iconClassName={classes.largeIcon}
              title={<T message="Efficiency" />}
              className={classes.valueBlock}>
              <T message="Trucknet helps companies use their assets more effectively by selling unused loads to other companies." />
            </ValueBlock>
          </div>
        </Paper>
      </Container>
    );
  }
}

export default withStyles(styles)(CoreValues);
