import {
  Container,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import cn from "classnames";
import React from "react";
import { T } from "react-targem";

import { styles } from "./EasyToUse.styles";
import InterfaceSwitcher from "./InterfaceSwitcher";

export type EasyToUseProps = WithStyles<typeof styles> & {
  className?: string;
};

class EasyToUse extends React.PureComponent<EasyToUseProps> {
  public render() {
    const { classes, className } = this.props;
    return (
      <Container className={cn(classes.container, className)}>
        <Typography className={classes.title} variant="h3">
          <T message="Easy to use" />
        </Typography>

        <InterfaceSwitcher className={classes.interfaceSwitcher} />
      </Container>
    );
  }
}

export default withStyles(styles)(EasyToUse);
