import {
  Container,
  Grid,
  Paper,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import cn from "classnames";
import GatsbyImage from "gatsby-image";
import React from "react";
import { T } from "react-targem";

import { withPageData, WithPageData } from "@src/contexts/PageDataContext";
import { IndexPageProps } from "@src/templates/IndexPage";

import { styles } from "./Partnership.styles";

export type PartnershipProps = WithStyles<typeof styles> &
  WithPageData<IndexPageProps> & {
    className?: string;
  };

class Partnership extends React.PureComponent<PartnershipProps> {
  public render() {
    const { classes, className, pageData } = this.props;

    return (
      <div className={cn(className, classes.blockContainer)}>
        <div className={classes.backgroundContainer}>
          <GatsbyImage
            className={classes.background}
            fluid={pageData.backgroundPartnership.childImageSharp.fluid}
          />
        </div>
        <Container className={classes.container}>
          <Paper className={classes.paper}>
            <Typography className={classes.title} variant="h3">
              <T message="Partnerships" />
            </Typography>
            <Grid className={classes.grid} container justify="space-around">
              {pageData.partners.edges.map(({ node }, index) => (
                <Grid key={index} className={classes.partner} item>
                  <GatsbyImage
                    className={classes.logo}
                    fixed={node.childImageSharp.fixed}
                  />
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Container>
      </div>
    );
  }
}

export default withPageData(withStyles(styles)(Partnership));
