import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
    interfaces: {
      flexGrow: 9,
      flexBasis: 0,
      paddingLeft: theme.spacing(11),
      [theme.breakpoints.down("sm")]: {
        paddingLeft: theme.spacing(3),
      },
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 0,
      },
    },
    media: {
      flexGrow: 10,
      flexBasis: 0,
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(4),
      },
    },
    image: {
      maxHeight: "400px",
      marginLeft: theme.spacing(-1),
      width: `calc(100% + ${theme.spacing(2)}px)`,
      "& img": {
        objectFit: "contain !important" as "contain",
      },
    },
    video: {
      marginTop: theme.spacing(-2),
      marginLeft: theme.spacing(-3),
      width: `calc(100% + ${theme.spacing(6)}px)`,
      height: "100%",
      objectFit: "contain",
      objectPosition: "top",
      [theme.breakpoints.down("sm")]: {
        maxHeight: "400px",
      },
    },
    button: {
      width: "100%",
      whiteSpace: "nowrap",
      marginBottom: theme.spacing(3),
    },
    signUp: {
      marginTop: theme.spacing(6),
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
  });
