import { Hidden, withStyles, WithStyles } from "@material-ui/core";
import cn from "classnames";
import GatsbyImage from "gatsby-image";
import React from "react";

import Transition from "@src/components/Transition";
import Video from "@src/components/Video";

// tslint:disable: no-relative-imports
import LargeButton from "@src/components/LargeButton";
import List from "../List";
import { SwitcherItemElement } from "../SwitcherItem";
import { styles } from "./Desktop.styles";

export type InterfaceSwitcherDesktopProps = WithStyles<typeof styles> & {
  className?: string;
  manager: SwitcherItemElement;
  driver: SwitcherItemElement;
};

type InterfaceSwitcherDesktopState = {
  selectedContent: SwitcherItemElement;
};

class InterfaceSwitcherDesktop extends React.PureComponent<
  InterfaceSwitcherDesktopProps,
  InterfaceSwitcherDesktopState
> {
  constructor(props: InterfaceSwitcherDesktopProps) {
    super(props);

    this.state = {
      selectedContent: props.manager,
    };
  }

  public render() {
    const { classes, className, manager, driver } = this.props;
    const { selectedContent } = this.state;
    const { image, video } = selectedContent.props;
    const managerActive = selectedContent === manager;

    const buttons = (
      <>
        <LargeButton
          className={classes.button}
          active={managerActive}
          icon={manager.props.buttonIcon}
          onClick={this.selectManager}>
          {manager.props.title}
        </LargeButton>
        <LargeButton
          className={classes.button}
          active={!managerActive}
          icon={driver.props.buttonIcon}
          onClick={this.selectDriver}>
          {driver.props.title}
        </LargeButton>
      </>
    );

    const actionButtons = (
      <div className={classes.signUp}>
        {selectedContent.props.actionButtons}
      </div>
    );

    return (
      <div className={cn(classes.container, className)}>
        <Hidden mdUp>{buttons}</Hidden>

        <div className={classes.media}>
          <Hidden mdUp>
            <Transition changeKey={managerActive.toString()}>
              <GatsbyImage
                className={classes.image}
                fluid={image.childImageSharp.fluid}
              />
            </Transition>
          </Hidden>
          <Hidden smDown implementation="css">
            <Video className={classes.video} src={video} />
          </Hidden>
        </div>

        <div className={classes.interfaces}>
          <Hidden smDown implementation="css">
            {buttons}
          </Hidden>

          <List content={selectedContent} />
          <Hidden smDown implementation="css">
            {actionButtons}
          </Hidden>
        </div>

        <Hidden mdUp>{actionButtons}</Hidden>
      </div>
    );
  }

  private switchContent = (selectedContent: SwitcherItemElement) => {
    this.setState({ selectedContent });
  };

  private selectManager = () => this.switchContent(this.props.manager);
  private selectDriver = () => this.switchContent(this.props.driver);
}

export default withStyles(styles)(InterfaceSwitcherDesktop);
