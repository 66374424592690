import { WithStyles, withStyles } from "@material-ui/core";
import React from "react";

import { styles } from "./IndexTemplate.styles";

import CoreValues from "./CoreValues";
import EasyToUse from "./EasyToUse";
import Partnership from "./Partnership";
import PerfectMatch from "./PerfectMatch";
// import Reviews from "./Reviews";
import TryTrucknet from "./TryTrucknet";
import WhatIsTrucknet from "./WhatIsTrucknet";

export type IndexTemplateProps = WithStyles<typeof styles> & {};

class IndexTemplate extends React.Component<IndexTemplateProps> {
  public render() {
    const { classes } = this.props;
    return (
      <div>
        <PerfectMatch className={classes.perfectMatch} />
        <CoreValues className={classes.coreValues} />
        <WhatIsTrucknet className={classes.whatIsTrucknet} />
        <EasyToUse className={classes.easyToUse} />
        {/* <Reviews className={classes.reviews} /> */}
        <Partnership className={classes.partnership} />
        <TryTrucknet className={classes.tryTrucknet} />
      </div>
    );
  }
}

export default withStyles(styles)(IndexTemplate);
