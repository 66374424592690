import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    blockContainer: {
      backgroundColor: theme.palette.primary.main,
    },
    container: {
      display: "flex",
      paddingTop: theme.spacing(17),
      paddingBottom: theme.spacing(19),
      justifyContent: "space-between",
      position: "relative",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      },
    },
    textContainer: {
      maxWidth: "480px",
      marginRight: theme.spacing(2),
    },
    title: {
      color: theme.palette.common.white,
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(2),
    },
    textAbout: {
      color: theme.palette.common.white,
    },
    button: {
      marginTop: theme.spacing(6),
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    youtube: {
      width: "100%",
      height: "100%",
    },
    youtubeContainer: {
      width: "100%",
      height: "100%",
      margin: 0,
    },
    greenDots: {
      position: "absolute",
      bottom: "-410px",
      right: "-84px",
      [theme.breakpoints.down("md")]: {
        bottom: "-510px",
      },
    },
  });
