import { withStyles, WithStyles } from "@material-ui/core";
import React from "react";

import { styles } from "./Screen.styles";

export type ScreenProps = WithStyles<typeof styles> & {
  className?: string;
};

class Screen extends React.PureComponent<ScreenProps> {
  public render() {
    const { classes, children } = this.props;

    return (
      <div className={classes.screenContainer}>
        <div className={classes.screen}>
          <div className={classes.screenBorder}>
            <div className={classes.videoContainer}>{children}</div>
          </div>
        </div>
        <div className={classes.screenLeg} />
      </div>
    );
  }
}

export default withStyles(styles)(Screen);
