import { withStyles, WithStyles } from "@material-ui/core";
import cn from "classnames";
import React from "react";

import Transition from "@src/components/Transition";

import { styles } from "./Video.styles";

export type VideoProps = {
  className?: string;
  containerClassName?: string;
  src: string;
};

class Video extends React.PureComponent<
  WithStyles<typeof styles> & VideoProps
> {
  public render() {
    const { classes, className, containerClassName, src } = this.props;
    return (
      <Transition className={containerClassName} changeKey={src}>
        <video className={cn(classes.video, className)} autoPlay loop muted>
          <source src={src} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Transition>
    );
  }
}

export default withStyles(styles)(Video);
