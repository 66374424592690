import { createStylesWithProps } from "@src/utils/styles";

import { TransitionProps } from "./Transition";

const createStyles = createStylesWithProps<TransitionProps>();

export const styles = () =>
  createStyles({
    transitionGroup: {
      position: "relative",
      width: "100%",
      height: "100%",
    },
    enter: {
      opacity: 0,
      position: "absolute !important" as "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    enterActive: {
      opacity: 1,
      transitionDuration: (props) => `${props.duration}ms`,
      transitionProperty: "opacity",
      transitionTimingFunction: "ease-in",
    },
    exit: {
      opacity: 1,
    },
    exitActive: {
      opacity: 0,
      transitionDuration: (props) => `${props.duration}ms`,
      transitionProperty: "opacity",
      transitionTimingFunction: "ease-in",
    },
  });
