import { Button, ButtonProps, withStyles, WithStyles } from "@material-ui/core";
import { SvgIconComponent } from "@material-ui/icons";
import cn from "classnames";
import React from "react";

import { styles } from "./LargeButton.styles";

export type LargeButtonProps = Omit<ButtonProps, "ref"> & {
  active?: boolean;
  icon?: SvgComponent | SvgIconComponent;
};

class LargeButton extends React.PureComponent<
  WithStyles<typeof styles> & LargeButtonProps
> {
  public render() {
    const {
      active,
      className,
      classes,
      children,
      icon: Icon,
      ...rest
    } = this.props;
    return (
      <Button
        className={cn(classes.button, { [classes.active]: active }, className)}
        variant="contained"
        size="large"
        disabled={!!active}
        {...rest}>
        {Icon && <Icon className={classes.icon} />}
        <span className={classes.text}>{children}</span>
      </Button>
    );
  }
}

export default withStyles(styles)(LargeButton);
