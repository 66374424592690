import { PermIdentity } from "@material-ui/icons";
import React from "react";
import { T } from "react-targem";

import TruckSvg from "@src/assets/icons/Truck.svg";
import interfaceDriverMp4 from "@src/assets/video/interface_driver.mp4";
import interfaceManagerMp4 from "@src/assets/video/interface_manager.mp4";
import { EnterButton } from "@src/components/CommonButtons";
import { withPageData, WithPageData } from "@src/contexts/PageDataContext";
import { IndexPageProps } from "@src/templates/IndexPage";
import DownloadButtons from "./DownloadButtons";

import Desktop from "./Desktop";
import SwitcherItem, { SwitcherItemElement } from "./SwitcherItem";

export type InterfaceSwitcherProps = WithPageData<IndexPageProps> & {
  className?: string;
};

class InterfaceSwitcher extends React.PureComponent<InterfaceSwitcherProps> {
  public render() {
    const { className, pageData } = this.props;

    const manager = (
      <SwitcherItem
        buttonIcon={PermIdentity}
        title={<T message="Manager Interface" />}
        description={
          <T message="The main interface is a web-based system for the  manager of a transportation company, who can:" />
        }
        image={pageData.managerInterface}
        video={interfaceManagerMp4}
        actionButtons={<EnterButton />}>
        <T message="Publish outsourcing requests and empty rides" />
        <T message="Search for outsourcing requests from other companies" />
        <T message="Manage schedules in Trucknet TMS (Roster)" />
        <T message="Create contracts between companies" />
        <T message="See the location of the outsourced truck" />
        <T message="Get an e-CMR document for any ride" />
      </SwitcherItem>
    ) as SwitcherItemElement;

    const driver = (
      <SwitcherItem
        buttonIcon={TruckSvg}
        title={<T message="Driver Interface" />}
        description={
          <T message="An application for the driver of a truck, which can be  installed to any Android and iOS device, including Telematics in  the truck." />
        }
        listTitle={<T message="Driver can" />}
        image={pageData.driverInterface}
        video={interfaceDriverMp4}
        actionButtons={<DownloadButtons />}>
        <T message="View all his rides from the Trucknet TMS" />
        <T message="Change the status of his rides" />
        <T message="Receive the signature of a consignee" />
        <T message="View e-CMR documents" />
      </SwitcherItem>
    ) as SwitcherItemElement;

    return <Desktop className={className} manager={manager} driver={driver} />;
  }
}

export default withPageData(InterfaceSwitcher);
