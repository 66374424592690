import React from "react";
import { T } from "react-targem";

import { Typography, withStyles, WithStyles } from "@material-ui/core";
import AndroidSvg from "@src/assets/icons/Android.svg";
import AppleSvg from "@src/assets/icons/Apple.svg";
import Link from "@src/components/Link";
import { styles } from "./DownloadButtons.styles";

export interface DownloadButtonsProps extends WithStyles<typeof styles> {}

export class DownloadButtons extends React.PureComponent<DownloadButtonsProps> {
  public render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <Typography variant="body1">
          <T message="Choose your platform:" />
        </Typography>
        <div className={classes.buttonsContainer}>
          <Link
            className={classes.iconLink}
            external
            to="https://apps.apple.com/il/app/trucknet-app/id1320414110">
            <AppleSvg className={classes.icon} />
          </Link>
          <Link
            className={classes.iconLink}
            external
            to="https://play.google.com/store/apps/details?id=com.trucknetdriverinterface">
            <AndroidSvg className={classes.icon} />
          </Link>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(DownloadButtons);
