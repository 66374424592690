import { graphql } from "gatsby";

// Pages cannot be exported using `export { default } from "..."`
// shorthand due to Gatsby bug https://github.com/gatsbyjs/gatsby/issues/12384
import IndexPage from "@src/templates/IndexPage";
export default IndexPage;

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1200) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const fluidImageMobile = graphql`
  fragment fluidImageMobile on File {
    childImageSharp {
      fluid(maxWidth: 960) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const interfaceImage = graphql`
  fragment interfaceImage on File {
    childImageSharp {
      fluid(maxWidth: 600) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const avatar = graphql`
  fragment avatar on File {
    childImageSharp {
      fluid(maxWidth: 72) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const partnerLogo = graphql`
  fragment partnerLogo on File {
    childImageSharp {
      fixed(height: 120) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`;

export const pageQuery = graphql`
  query IndexPageQuery {
    managerInterface: file(
      relativePath: { eq: "images/interface_manager.png" }
    ) {
      ...interfaceImage
    }
    driverInterface: file(relativePath: { eq: "images/interface_driver.png" }) {
      ...interfaceImage
    }
    avatar1: file(relativePath: { eq: "images/avatar1.png" }) {
      ...avatar
    }
    avatar2: file(relativePath: { eq: "images/avatar2.png" }) {
      ...avatar
    }
    avatar3: file(relativePath: { eq: "images/avatar3.png" }) {
      ...avatar
    }
    backgroundPartnership: file(
      relativePath: { eq: "images/background_partner.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1120) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    partners: allFile(
      filter: { relativePath: { regex: "^images/partners/" } }
      sort: { fields: relativePath }
    ) {
      edges {
        node {
          ...partnerLogo
        }
      }
    }
    tryTrucknetDesktop: file(
      relativePath: { eq: "images/try_trucknet_desktop.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tryTrucknetMobile: file(
      relativePath: { eq: "images/try_trucknet_mobile.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 355) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
